import React from 'react'
import { Link } from 'react-router-dom';
import Button from '../components/button';

function ErrorPage() {
  return (
    <section className='error-wrapper'>
    <div className="error-content">
      <h1>Page not found</h1>
      <div className="btn-wrap">
        <Link to='/'>
          <Button
            className='btn-primary'
            btnTitle='Go to Homepage'
          />
        </Link>
      </div>
    </div>
  </section>
  )
}

export default ErrorPage;