import React from 'react';
import "./blockTitle.scss";

function BlockTitle(props) {
    return (
        <div className={`block-title ${props.addClass}`}>
            <h6>{props.title}</h6>
            <h2>
                {props.subTitle}
            </h2>
            <p>{props.content}</p>
        </div>
    )
};

export default BlockTitle;