import React from 'react';
import { Col, Row } from 'react-grid-system';
import { FiGithub } from "react-icons/fi";
import { TfiLinkedin, TfiSkype } from "react-icons/tfi";


function Footer() {
  return (
    <footer className='site-footer'>
      <div className="site-footer__top">
        <div className="custom-container">
          <Row className='row'>
            <Col lg={3} className='col-1'>
              <a href='#profile' className='footer-logo'>
                <span>sharma</span>
              </a>
            </Col>
            <Col lg={3} className='col-2'>
              <div className="social-list">
                <h4>Follow with us :</h4>
                <ul>
                <li className='linkedin-icon'>
                        <a href="https://www.linkedin.com/in/aashik-sharma-377237260/" target="_blank" rel="noreferrer"><TfiLinkedin /></a>
                      </li>
                      <li className='github-icon'>
                        <a href="https://github.com/Aashik81443/" target="_blank" rel="noreferrer"><FiGithub /></a>
                      </li>
                      <li className='skype-icon'>
                        <a href="skype:Aashik Sharma - Fed Dev56 - 9812181443?chat" target="_blank" rel="noreferrer"><TfiSkype /></a>
                      </li>
                </ul>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <div className="site-footer__bottom">
        <div className="custom-container">
          <span className='copyright'>
            © 2023 | Designed by <a href="#profile" className='link-text'>Aashik Sharma</a>. &#x2060;All rights reserved.
          </span>
        </div>
      </div>
    </footer>
  )
};

export default Footer;