import React, {useEffect} from 'react';
// import { Col, Row } from 'react-grid-system';
import "./resumeCard.scss";
import AOS from "aos";
import "aos/dist/aos.css";

function ResumeCard(props) {
    useEffect(() => {
        AOS.init({
          offset:50,
          duration: 1000,
          easing: "ease",
        });
    
        AOS.refresh();
        setTimeout(function () {
          AOS.init();
        }, 1000);
      }, []);
    return (
        <div className={`resume-card ${props?.className}`} data-aos="fade-up">
            <div className="time-period">
                <span>{props?.content?.timeline}</span>
            </div>
            <div className="content">
                <h4>{props?.content?.course}</h4>
                <span className='org'>{props?.content?.institute}</span>
                <span className='per'>{props?.content?.percentage}</span>
            </div>
        </div>
    );
}

export default ResumeCard;