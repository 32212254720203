import React from 'react';
import "./filterMenu.scss";
import { filterNavData } from '../../helpers/constants';

function FilterMenu(props) {
   
    return (
        <ul className='filter-menu'>
            {filterNavData.map((item) =>
                <li
                    className={`filter-menu__item ${item.id === props.activeMenu ? 'active' : ''}`}
                    key={item.id}
                    onClick={() => props.onChange(item.id)}
                >
                    {item.label}
                </li>
            )}
        </ul>
    );
}

export default FilterMenu;