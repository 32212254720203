import React, { useState, useEffect } from 'react';
import "./projectCard.scss";
import { FiHeart } from "react-icons/fi";
import { ImArrowRight2, ImCross, ImArrowUpRight2 } from "react-icons/im";
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import Button from '../../button';
import AOS from "aos";
import "aos/dist/aos.css";


function ProjectCard(props) {
    const [open, setOpen] = useState(false);

    const onOpenModal = () => setOpen(true);
    const onCloseModal = () => setOpen(false);

    useEffect(() => {
        AOS.init({
            offset: 50,
            duration: 1000,
            easing: "ease",
        });

        AOS.refresh();
        setTimeout(function () {
            AOS.init();
        }, 1000);
    }, []);
    return (
        <>
            <div className='project-card' data-aos="fade-up">
                <div className="project-card__media">
                    <img src={props?.content?.projectImg} alt={props?.content?.projectImgAlt} />
                </div>
                <div className="project-card__content">
                    <div className="heading">
                        <span className='category'>{props?.content?.category}</span>
                        <span className='like'><FiHeart />12</span>
                    </div>
                    <h4>{props?.content?.title}</h4>
                    <div className="cta-wrap stretched-link" onClick={onOpenModal}>
                        <span onClick={onOpenModal}>{props?.content?.btnTitle}<ImArrowRight2 /></span>
                    </div>
                </div>
            </div>

            <Modal open={open} onClose={onCloseModal} center showCloseIcon={false}>
                <div className="project-modal__content">
                    <button onClick={onCloseModal} className="cross-icon"><ImCross /></button>
                    <h3>{props?.content?.title}</h3>
                    <hr />
                    <div className="media-screenshot">
                        <img src={props?.content?.projectImg} alt={props?.content?.projectImgAlt} />
                    </div>
                    <div dangerouslySetInnerHTML={{ __html: props?.content?.projectDesc }}>

                    </div>
                    <div className="btn-wrap">
                        <button onClick={onCloseModal} className="btn btn-sm">cancel</button>
                        {props?.content?.btnUrl &&
                            <a href={props?.content?.btnUrl} target="_blank" rel="noreferrer">
                                <Button
                                    className='btn-sm'
                                    btnTitle='View Site'
                                    btnIcon={<ImArrowUpRight2 />}
                                />
                            </a>
                        }
                    </div>
                </div>
            </Modal>
        </>
    );
}

export default ProjectCard;