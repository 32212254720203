import React from 'react';
import "./button.scss";

function Button(props) {
    return (
        (props.btnTitle &&
            <button
                className={`btn ${props.className}`}>
                {props.btnTitle}
                {props.btnIcon}
            </button>
        )
    )
}

export default Button