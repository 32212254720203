import React from 'react';
import { Link } from 'react-router-dom';
import "./dashboard.scss";

function Dashboard() {
    return (
        <section className='dash-wrap' style={{ paddingTop: 200 }}>
            <aside className='left-content'>
                <div className="dash-logo">
                    <Link to='/'>
                        Sharma
                    </Link>
                </div>
                <div className="dash-menu">
                    <ul>
                        <li className='active'>dashboard</li>
                        <li>dashboard</li>
                        <li>dashboard</li>
                        <li>dashboard</li>
                    </ul>
                </div>
            </aside>
            <div className="right-content">
                <div className="header-top">
                    <div className="icon">
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                    <div className="log">
                        <h5>Logout</h5>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Dashboard;