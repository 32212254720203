import './App.scss';
import Header from './components/header';
import Footer from './components/footer';
import { Route, Routes } from "react-router-dom";
import Home from './pages/home';
import ErrorPage from './pages/errorPage';
import Dashboard from './pages/dashboard';

function App() {
  return (
    <div className="site-wrapper">
      <Header />
      <main className="site-content">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path='*' element={<ErrorPage to="/" />} />
        </Routes>
      </main>
      <Footer />
    </div>
  );
}

export default App;
