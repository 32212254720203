import React from 'react';
import { useLocation } from 'react-router-dom';
import { menuItems } from '../../helpers/constants';
import "./navbar.scss";

function Navbar({ onClick }) {
    const router = useLocation();
    return (
        <ul className='navbar-nav'>
            {menuItems.map((item) =>
                <li
                    onClick={onClick}
                    className={`nav-item ${router.hash === item.url ? 'active' : router.hash === '' && item.url === '#profile' ? 'active' : ''}`}
                    key={item.id}>
                    <a href={item.url} className="nav-link">{item.title}</a>
                </li>
            )}
        </ul>
    );
}

export default Navbar;