import React, { useRef, useState } from 'react';
import "./contactForm.scss";
// import Button from "../button";
import { AiOutlineSend } from "react-icons/ai";
import emailjs from '@emailjs/browser';
import { SERVICE_ID, TEMPLETE_ID, PUBLIC_KEY } from '../../helpers/constants';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ImSpinner } from "react-icons/im";

function ContactForm() {
    const [loading, setLoading] = useState(false);
    const form = useRef();

    function sendEmail(e) {
        setLoading(true)
        e.preventDefault();

        emailjs.sendForm(SERVICE_ID, TEMPLETE_ID, form.current, PUBLIC_KEY)
            .then((result) => {
                console.log(result.text);
                toast.success('Your message has been sent!', {
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                setLoading(false);
                e.target.reset();
            }, (error) => {

                console.log('error', error.text);
                toast.error('Something went wrong!', {
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                setLoading(false);

            });
    }
    return (
        <div className='form-holder'>
            <form className='form' ref={form} onSubmit={sendEmail}>
                <div className="form-group">
                    <input type="text" placeholder='Enter Your Name' name='user_name' className='form-control' required />
                </div>
                <div className="form-group">
                    <input type="email" placeholder='Enter Your Email' name='user_email' className='form-control' required />
                </div>
                <div className="form-group">
                    <textarea placeholder='write message here...' name='message' className='form-control'></textarea>
                </div>

                <div className="btn-wrap">
                    <button className='btn btn-primary btn-sm'>
                        {loading ? <>Sending<ImSpinner className='spinner' /></> : <>Send<AiOutlineSend /></>}</button>
                    {/* <Button
                        className='btn-sm'
                        btnTitle='Send'
                        btnIcon={<AiOutlineSend />}
                    /> */}
                </div>
            </form>
            <ToastContainer />
        </div>
    )
}

export default ContactForm