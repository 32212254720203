import React, { useEffect, useState } from 'react';
// import { Link } from 'react-router-dom';
import { CgMenuRight } from "react-icons/cg";
import { RxCross2 } from "react-icons/rx";
import Navbar from './nav';


function Header() {
  const [active, setActive] = useState(false);

  const handleClick = () => {
    setActive(!active);
  };
  const handleNavItemClick = () => {
    setActive(false)
  }

  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 50);
    });
  }, []);

  return (
    <header className={`site-header ${scroll ? "sticky" : ""}`}>
      <div className='custom-container'>
        <div className="site-header__wrap">
          <a href='#profile' className='site-header__logo'>
            <span>sharma</span>
          </a>
          <button className="menu-icons" onClick={handleClick}>
            {active ? <RxCross2 /> : <CgMenuRight />}

          </button>

          <nav className={active ? "navbar" : "navbar active"}>
            <Navbar onClick={handleNavItemClick} />
          </nav>
        </div>
      </div>

    </header>
  )
};

export default Header;