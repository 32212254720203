import React from 'react';
import "./blockquote.scss";

function Blockquote(props) {
    return (
        <blockquote className='blockquote'>
            <p>{props?.data?.content}</p>
            <div className="author-media">
                <img src={props?.data?.authorImg} alt={props?.data?.alt} />
            </div>
            <h3>{props?.data?.authorName}
                <span>{props?.data?.authorPos}</span>
            </h3>
        </blockquote>
    )
}

export default Blockquote