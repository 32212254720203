import React, { useEffect, useState } from 'react';
import "./home.scss";
import { Col, Row } from "react-grid-system";
import profileImg from "../images/profile-img2.png"
import aboutImg from "../images/about-img3.png"
import Button from '../components/button';
import BlockTitle from '../components/blockTitle';
import ContactForm from '../components/contactForm';
import { TfiLinkedin, TfiSkype } from "react-icons/tfi";
import { MdFileDownload, MdContactMail } from "react-icons/md";
import { FiGithub } from "react-icons/fi";
import { skillData, serviceData, testimonialData, eduData, experienceData, projectData } from '../helpers/constants';
import ServiceCard from '../components/cards/serviceCard';
import hiringBg from '../images/hiring-bg.jpeg';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Blockquote from '../components/blockquote';
import ProjectCard from '../components/cards/projectCard';
import ResumeCard from '../components/cards/resumeCard';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { FaGraduationCap, FaBriefcase } from "react-icons/fa";
import FilterMenu from '../components/filterMenu';
import resume from "../images/resume.pdf";
import AOS from "aos";
import "aos/dist/aos.css";

function Home() {
  useEffect(() => {
    document.title = 'Aashik Sharma'
  })
  const settings = {
    arrows: true,
    dots: true,
    autoplay: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          dots: false,
        }
      }
    ]
  };

  useEffect(() => {
    AOS.init({
      offset:50,
      duration: 1000,
      easing: "ease",
    });

    AOS.refresh();
    setTimeout(function () {
      AOS.init();
    }, 1000);
  }, []);

  const [menuActive, setMenuActive] = useState('all');

  const handleMenuClick = (id) => {
    setMenuActive(id)
  }

  return (
    <>
      <section className='profile-block padding-t' id='profile'>
        <div className="custom-container">
          <Row className='row'>
            <Col lg={6} className='right'>
              <div className="profile-media">
                <img src={profileImg} alt="profile media" />
              </div>
            </Col>
            <Col lg={6} className='left'>
              <div className="profile-info">
                <h1>
                  <span className='t-text'>Hi!</span>
                  I'm <span className='c-text'>Aashik Sharma</span>
                  <span className='b-text'>A Frontend Developer</span>
                </h1>
                <p>I have a track record of successfully managing projects and delivering high-quality software solutions. More than two of experience in this field, I am passionate about providing expectational service to my clients. A detailed overview of my professional experience, skills, and project are on this website, please contact me if you would like to discuss a potential project. Thanks for visiting.</p>
                <div className="btn-wrap">
                  <a href="#contact">
                    <Button
                      btnTitle='Hire Me'
                    />
                  </a>
                  <div className="social-list">
                    <ul>
                      <li className='linkedin-icon'>
                        <a href="https://www.linkedin.com/in/aashik-sharma-377237260/" target="_blank" rel="noreferrer"><TfiLinkedin /></a>
                      </li>
                      <li className='github-icon'>
                        <a href="https://github.com/Aashik81443/" target="_blank" rel="noreferrer"><FiGithub /></a>
                      </li>
                      <li className='skype-icon'>
                        <a href="skype:live:aashiksharma12345?chat" target="_blank" rel="noreferrer"><TfiSkype /></a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </section>

      <section className='about-block padding-t padding-b bg-light-gray' id='about-us'>
        <div className="custom-container">
          <Row className='about-row'>
            <Col lg={5.5}>
              <div className="about-media__wrap" data-aos="fade-right">
                <div className="about-media">
                  <img src={aboutImg} alt="About" />
                </div>
              </div>
            </Col>
            <Col lg={6.5}>
              <div className="about-content" data-aos="fade-left">
                <BlockTitle
                  addClass=' '
                  title='About'
                  subTitle='Know Me More'
                />
                <p>Hello! I am a frontend developer with expertise in  HTML, CSS, JavaScript, Photoshop, UI/UX design making me proficient in front-end development. I specialize in delivering high-quality, scalable Web solutions using technologies such as HTML, CSS, JavaScript, and React, and have a strong understanding of responsive design.</p>
                <p>My proficiency in back-end development includes the use of Php, Wordpress/CMS, javascript , and databases. I have hands-on experience with Wordpress, and am adept at managing and maintaining SEO of websites. My project management track record and Git proficiency, coupled with strong communication and problem-solving skills, make me a valuable asset.</p>
                <p>My driving force is to assist organizations in achieving their goals through technology, utilizing my skills and experience. I'm enthusiastic about expanding my knowledge and contributing to impactful projects.</p>

                <div className="experience-title">
                  <span className='h-text'>2+</span><span className='s-text'> Years of <strong>Experience</strong></span>
                </div>

                <div className="btn-wrap">
                  <a href={resume} target="_blank" rel="noreferrer">
                    <Button
                      btnTitle='Download cv'
                      btnIcon={<MdFileDownload />}
                    />
                  </a>
                  <a href="#contact">
                    <Button
                      btnTitle='Contact'
                      btnIcon={<MdContactMail />}
                    />
                  </a>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </section>

      <section className="skills-block padding-t padding-b" id='skills'>
        <div className="custom-container">
          <BlockTitle
            addClass='text-center'
            title='my skills'
            subTitle="Here are a few technologies I’ve been working with recently:"
          />

          <div className="skill-list">
            <ul>
              <Row className='row'>
                {skillData.map((item, index) =>
                  <Col lg={6} className="col" key={index}>
                    <li data-aos="fade-up">{item}</li>
                  </Col>
                )}
              </Row>
            </ul>
          </div>
        </div>
      </section>

      <section className="services-block padding-t padding-b bg-light-gray" id='what-i-do'>
        <div className="custom-container">
          <BlockTitle
            addClass='text-center'
            title='What i Do?'
            subTitle="How I can help your next project"
          />

          <Row className='row'>
            {serviceData.map((item, index) =>
              <Col lg={4} md={6} className="col" key={index} data-aos="fade-up">
                <ServiceCard
                  content={item}
                />
              </Col>
            )}
          </Row>
        </div>
      </section>

      <section className="resume-block padding-t padding-b" id='resume'>
        <div className="custom-container">
          <BlockTitle
            addClass='text-center'
            title='Resume'
            subTitle="A summary of My Resume"
          />

          <div className="resume-wrap">
            <Tabs>
              <TabList>
                <Tab>Education<FaGraduationCap /></Tab>
                <Tab>Experience<FaBriefcase /></Tab>
              </TabList>

              <TabPanel>
                {eduData.map((item, index) =>
                  <ResumeCard
                    content={item}
                    key={index}
                    className={index % 2 === 0 ? "" : "resume-card--alt"}
                  />
                )}
              </TabPanel>
              <TabPanel>
                {experienceData.map((item, index) =>
                  <ResumeCard
                    content={item}
                    key={index}
                    className={index % 2 === 0 ? "" : "resume-card--alt"}
                  />
                )}
              </TabPanel>
            </Tabs>
          </div>
        </div>
      </section>

      <section className='portfolio-block padding-t padding-b bg-light-gray' id='portfolio'>
        <div className="custom-container">
          <BlockTitle
            addClass='text-center'
            title='Portfolio'
            subTitle="Some of my most recent projects"
          />

          <div className="portfolio-content">
            <div className="filter-nav">
              <FilterMenu
                activeMenu={menuActive}
                onChange={(menu) => handleMenuClick(menu)}
              />
            </div>
            <Row className='row'>
              {projectData.map((item, index) => (
                menuActive === item.filterId ?
                  <Col lg={4} md={6} className="col" key={index}>
                    <ProjectCard
                      content={item}
                    />
                  </Col> : menuActive === 'all' ?
                    <Col lg={4} md={6} className="col" key={index}>
                      <ProjectCard
                        content={item}
                      />
                    </Col> : null
              ))}
            </Row>
          </div>
        </div>
      </section>

      <section className='hiring-block primary-overlay' id='hiring' style={{ background: `url(${hiringBg})` }}>
        <div className="custom-container">
          <div className="hiring-content">
            <h2>Interested in working with me?</h2>
            <div className="btn-wrap">
              <a href="#contact">
                <Button
                  className='btn--alt'
                  btnTitle='Hire Me'
                />
              </a>
            </div>
          </div>
        </div>
      </section>

      <section className="testimonial-block padding-t padding-b" id='testimonial'>
        <div className="custom-container">
          <BlockTitle
            addClass='text-center'
            title='Clients Speak'
            subTitle="What Some of my Clients Say"
          />
          <div className="testimonial-content">
            <Slider {...settings} className='testimonial-slider'>
              {testimonialData.map((item, index) =>
                <div className="testimonial-slider__item" key={index}>
                  <Blockquote
                    data={item}
                  />
                </div>
              )}
            </Slider>
          </div>
        </div>
      </section>

      <section className="contact-block padding-t padding-b bg-light-gray" id='contact'>
        <div className="custom-container">
          <BlockTitle
            addClass='text-center'
            title='Contact Me'
            subTitle="Just write us a message!"
          />
          <ContactForm />
        </div>
      </section>
    </>
  )
};

export default Home;