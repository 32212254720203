import authorImg from '../images/author-img.jpg';
import kifThmubnail from "../images/kif-thumbnail.jpg";
import jitenProjectThumbnail from "../images/jiten-project-thumbnail.jpg";
import manishaProjectThumbnail from "../images/manisha-project-thumbnail.png";
import { FaCode, FaPalette, FaRegWindowRestore } from "react-icons/fa";
import { MdWeb} from "react-icons/md";
import { ImVideoCamera} from "react-icons/im";


export const SERVICE_ID = 'service_juq72vv';
export const TEMPLETE_ID = 'template_4c2krze';
export const PUBLIC_KEY = '1MyZgcJ5fkCnrnmfI';

export const menuItems = [
    {
        id: 1,
        title: "About us",
        url: "#about-us"
    },
    {
        id: 2,
        title: "Skills",
        url: "#skills"
    },
    {
        id: 3,
        title: "What i Do",
        url: "#what-i-do"
    },
    {
        id: 4,
        title: "Resume",
        url: "#resume"
    },
    {
        id: 5,
        title: "portfolio",
        url: "#portfolio"
    },
    {
        id: 6,
        title: "Testimonials",
        url: "#testimonial"
    }, {
        id: 7,
        title: "Contact",
        url: "#contact"
    }
];

export const filterNavData = [
    {
        label: 'All',
        id: 'all'
    },
    {
        label: 'Websites',
        id: 'websites'
    },
    {
        label: 'Landing Page',
        id: 'landing-page'
    },
    {
        label: 'UI/UX Design',
        id: 'ui-design'
    }
]


export const featuresData = [

    {
        id: 1,
        Title: 'web development',
        Desc: ' Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quam natus itaque omnis quae totam, cupiditate illo dolores, labore repellat rerum nihil libero ullam nostrum asperiores.'
    },
    {
        id: 2,
        Title: 'ghrapics design',
        Desc: ' Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quam natus itaque omnis quae totam, cupiditate illo dolores, labore repellat rerum nihil libero ullam nostrum asperiores.'
    },
    {
        id: 3,
        Title: 'logo design',
        Desc: ' Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quam natus itaque omnis quae totam, cupiditate illo dolores, labore repellat rerum nihil libero ullam nostrum asperiores.'
    }
];


export const educationData = [
    {
        title: 'computer science engineering',
        organization_name: 'jhfg',
        percentage: '22%',
        duration: '2022-2022'
    }
]

export const skillData = [
    'Html/Css/js',
    'Bootstrap',
    'Wordpress',
    'React Js',
    'Photoshop',
    'Figma/XD',
    'Gatsby',
    'NextJS'
]

export const serviceData = [
    {
        icon: <MdWeb />,
        title: 'Web Design',
        desc: `Web design is creating website visual layout and user interface. It includes design elements such as colors, fonts, and graphics, and coding languages to create responsive websites.`
    },
    {
        icon: <FaCode />,
        title: 'Web Development',
        desc: `Web development is building and maintaining websites using programming languages. It includes front-end and back-end development for functional, interactive, and dynamic websites that can process data and communicate with other systems.`
    },
    {
        icon: <FaRegWindowRestore />,
        title: 'UI/UX Design',
        desc: `UI/UX design involves creating visual and interactive elements for digital products, focusing on aesthetics and user experience. It aims to create a seamless and enjoyable user experience while meeting business objectives.`
    },
    {
        icon: <FaPalette />,
        title: 'Graphic Design',
        desc: `Graphic design trends include bold colors, minimalism, custom illustrations, and 3D elements. UX design has influenced the field, prioritizing usability and accessibility. Social media has also created new opportunities for designers to showcase their work.`
    },
    {
        icon: <ImVideoCamera />,
        title: 'Video Editing',
        desc: `A good editor understands video production and editing techniques, color correction, audio mixing, and special effects. They collaborate with other professionals to meet client requirements.`
    }
]

export const testimonialData = [
    {
        content: `“I worked with Aashik sharma to build my portfolio website, and I'm thrilled with the results. He was communicative, responsive, and brought creative solutions to the table. The site is stunning, functional, easy to navigate, and optimized for search engines. He paid attention to every detail, and I now have a professional portfolio that showcases my skills and experience. I highly recommend Aashik sharma to anyone looking for a top-notch web developer who delivers high-quality results and exceptional customer service.”`,
        authorName: 'Er. Jitendra Sharma',
        authorPos: 'Electrical and Electronics Engineer',
        authorImg: authorImg,
        alt: 'Jitendra Sharma'
    },
    {
        content: '“Lorem ipsum dolor sit amet consectetur adipisicing elit. Minima earum eum, ullam officiis exercitationem at eveniet esse officia aliquam. Culpa ut nisi ex iste quaerat. Lorem ipsum dolor sit amet consectetur”',
        authorName: 'Roban Sharma',
        authorPos: 'Web Developer at Examples pvt.Ltd',
        authorImg: authorImg,
        alt: 'Jitendra Sharma'
    }
    ,
    {
        content: '“Lorem ipsum dolor sit amet consectetur adipisicing elit. Minima earum eum, ullam officiis exercitationem at eveniet esse officia aliquam. Culpa ut nisi ex iste quaerat. Lorem ipsum dolor sit amet consectetur adipisicing elit. Minima earum eum, ullam officiis exercitationem at eveniet esse officia aliquam. Culpa ut nisi ex iste quaerat.”',
        authorName: 'Roban Sharma',
        authorPos: 'Web Developer at Examples pvt.Ltd',
        authorImg: authorImg,
        alt: 'Jitendra Sharma'
    }
]

export const eduData = [
    {
        timeline: '2017-2021',
        course: 'Computer Science',
        institute: 'Uttrakhand Technical University, Dehradun',
        percentage: '70%'
    },
    {
        timeline: '2015-2017',
        course: 'High School',
        institute: 'Orchid public secondary school, Biratnagar',
        percentage: '58%'
    }
    ,
    {
        timeline: '2015',
        course: 'S.L.C',
        institute: 'MA VI Mithleshwar Mauwahi, Dhanusha',
        percentage: '77%'
    }
]
export const experienceData = [
    {
        timeline: 'Dec 2021 - Present',
        course: 'Frontend Developer',
        institute: 'SmartSites Nepal, Kathmandu'
    }
]

export const projectData = [
    {
        projectImg: kifThmubnail,
        projectImgAlt: 'Kif Church',
        category: 'Website',
        title: 'Kif Church',
        btnTitle: 'view',
        btnUrl: 'https://www.kifellowship.org/',
        filterId: 'websites',
        projectDesc: ` <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Eveniet eos alias at, mollitia illo quo ratione commodi ex voluptatum facilis tempore numquam in nisi quaerat? Sunt natus voluptatem non aliquam.</p>
        <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Eveniet eos alias at, mollitia illo quo ratione commodi ex voluptatum facilis tempore numquam in nisi quaerat? Sunt natus voluptatem non aliquam.</p>`

    },
    {
        projectImg: jitenProjectThumbnail,
        projectImgAlt: 'Jitendra Portfolio',
        category: 'Logo Design',
        title: 'Portfolio',
        btnTitle: 'view',
        btnUrl: 'https://jitendra-pf.netlify.app/',
        filterId: 'logo-design',
        projectDesc: ` <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Eveniet eos alias at, mollitia illo quo ratione commodi ex voluptatum facilis tempore numquam in nisi quaerat? Sunt natus voluptatem non aliquam.</p>
        <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Eveniet eos alias at, mollitia illo quo ratione commodi ex voluptatum facilis tempore numquam in nisi quaerat? Sunt natus voluptatem non aliquam.</p>`
    },
    {
        projectImg: manishaProjectThumbnail,
        projectImgAlt: 'Manishakawar website',
        category: 'Website',
        title: 'Manishakawar website',
        btnTitle: 'view',
        btnUrl: 'https://manishakawar.com.np/',
        filterId: 'websites',
        projectDesc: ` <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Eveniet eos alias at, mollitia illo quo ratione commodi ex voluptatum facilis tempore numquam in nisi quaerat? Sunt natus voluptatem non aliquam.</p>
        <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Eveniet eos alias at, mollitia illo quo ratione commodi ex voluptatum facilis tempore numquam in nisi quaerat? Sunt natus voluptatem non aliquam.</p>`
    },
    {
        projectImg: jitenProjectThumbnail,
        projectImgAlt: 'Jitendra Portfolio',
        category: 'Logo Design',
        title: 'Portfolio',
        btnTitle: 'view',
        btnUrl: 'https://jitendra-pf.netlify.app/',
        filterId: 'logo-design',
        projectDesc: ` <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Eveniet eos alias at, mollitia illo quo ratione commodi ex voluptatum facilis tempore numquam in nisi quaerat? Sunt natus voluptatem non aliquam.</p>
        <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Eveniet eos alias at, mollitia illo quo ratione commodi ex voluptatum facilis tempore numquam in nisi quaerat? Sunt natus voluptatem non aliquam.</p>`
    },
    {
        projectImg: kifThmubnail,
        projectImgAlt: 'Kif Church',
        category: 'Website',
        title: 'Kif Church',
        btnTitle: 'view',
        btnUrl: 'https://www.kifellowship.org/',
        filterId: 'websites',
        projectDesc: ` <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Eveniet eos alias at, mollitia illo quo ratione commodi ex voluptatum facilis tempore numquam in nisi quaerat? Sunt natus voluptatem non aliquam.</p>
        <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Eveniet eos alias at, mollitia illo quo ratione commodi ex voluptatum facilis tempore numquam in nisi quaerat? Sunt natus voluptatem non aliquam.</p>`

    },
    {
        projectImg: jitenProjectThumbnail,
        projectImgAlt: 'Jitendra Portfolio',
        category: 'Logo Design',
        title: 'Portfolio',
        btnTitle: 'view',
        btnUrl: 'https://jitendra-pf.netlify.app/',
        filterId: 'logo-design',
        projectDesc: ` <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Eveniet eos alias at, mollitia illo quo ratione commodi ex voluptatum facilis tempore numquam in nisi quaerat? Sunt natus voluptatem non aliquam.</p>
        <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Eveniet eos alias at, mollitia illo quo ratione commodi ex voluptatum facilis tempore numquam in nisi quaerat? Sunt natus voluptatem non aliquam.</p>`
    }
]