import React from 'react';
import "./serviceCard.scss";

function ServiceCard(props) {
    return (
        <div className='service-card'>
            <div className="service-card__icon">
                {/* <img src={props?.content?.icon} alt="Icon" /> */}
                {props?.content?.icon}
            </div>
            <div className="service-card__content">
                <h3>{props?.content?.title}</h3>
                <p>{props?.content?.desc}</p>
            </div>
        </div>
    );
}

export default ServiceCard;